// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

require('bootstrap')
require('stylesheets/application')
require("moment/locale/de")
require("tempusdominus-bootstrap-4")
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/mouse")
// require("jquery-ui/ui/widgets/sortable")
require("jquery-ui-touch-punch")
require("trix")
require("@rails/actiontext")
require('data-confirm-modal')

import Sortable, { AutoScroll, MultiDrag, Swap } from 'sortablejs';
Sortable.mount(new MultiDrag());
window.Sortable = Sortable;

import $ from 'jquery';
global.$ = jQuery;
import 'cocoon-js';

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/pagebreak'
import 'tinymce/themes/silver'
import 'tinymce-i18n/langs5/de'
global.tinymce = tinymce

import print from 'print-js'
global.print = print

var moment = require('moment-timezone');
document.cookie = 'timezone=' + moment.tz.guess() + ';';

window.options = {
  useCurrent: false,
  format: 'YYYY-MM-DD',
  locale: moment.locale('de'),
  ignoreReadonly: true,
  icons: {
    time: 'fas fa-clock',
    date: 'fas fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-day',
    clear: 'fas fa-trash',
    close: 'fas fa-backspace'
  }
}

function requireAll(context) {
  context.keys().forEach(context)
}
requireAll(require.context('custom/', true, /\.js$/))