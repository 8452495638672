dataConfirmModal.setDefaults({
  title: 'Bestätigung',
  commit: 'OK',
  cancel: 'Abbrechen',
});


$(document).on('cocoon:before-remove', function(e, container) {
  if (this.activeElement.hasClass('custom-confirm-remove-link')) {
    e.preventDefault();
    e.stopPropagation();

    var text = this.activeElement.getAttribute('data-text') || 'Möchten Sie dieses Element wirklich löschen?';
    var $this = $(this);
    dataConfirmModal.confirm({
      text: text,
      onConfirm: function() {
        $(container).remove();
        $this.trigger('cocoon:after-remove');
      }
    });
  }
});
