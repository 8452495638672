require('bootstrap-select')

$(document).on('turbolinks:load', function() {
  window.initialize()
});

 $(document).on('cocoon:after-insert', function() {
  window.initialize()
});

$(document).on("click", '[data-toggle="datepicker"]', function() {
  $(this).datetimepicker("toggle")
});

document.addEventListener("turbolinks:before-cache", function() {
  tinymce.remove()

  $('input.datepicker').datetimepicker("destroy");

  $('select').selectpicker('destroy')

  $('.with-tooltip').tooltip('hide')
})

window.initialize = function() {
  resizeLargeTables()
  $('.with-tooltip').tooltip({ container : 'body', html : true })

  $('select').selectpicker({
    noneSelectedText: "Bitte auswählen",
    selectAllText: "Alle auswählen",
    deselectAllText: "Alle abwählen",
    dropdownAlignRight: "auto"

  });

  $(".sortable").each(function() {
    sortableElement = $(this)[0]
    Sortable.create(sortableElement, {
      handle: '.handle',
      //draggable: ".draggable",
      group: sortableElement,
      supportPointer: true,
      fallbackTolerance: 5,
      onUpdate: function(event) {
        itemsIds = this.toArray()
        itemsName = event.item.id.replace(/_[0-9]+$/, '');
        oldIndex = event.oldIndex
        newIndex = event.newIndex

        if (oldIndex > newIndex) {
          startIndex = newIndex;
          endIndex = oldIndex;
        } else {
          startIndex = oldIndex;
          endIndex = newIndex;
        }
        
        changedItems = {}
        changedItems[itemsName] = {}
        for (index = startIndex; index <= endIndex; index++) {
          changedItems[itemsName][index] = itemsIds[index];
        }

        $.ajax({
          url: this.el.dataset.url,
          type: "PATCH",
          data: changedItems
        });
      }
    });
  });

  $(".multisortable").each(function() {
    sortableElement = $(this)[0]
    sortableInstance = Sortable.create(sortableElement, {
      handle: '.handle',
      // draggable: ".draggable",
      group: sortableElement,
      multiDrag: true,
      fallbackTolerance: 5,
      supportPointer: true,
      multiDragKey: 'CTRL',
      onUpdate: function(event) {
        itemsIds = this.toArray()
        itemsName = event.item.id.replace(/_[0-9]+$/, '');

        if (event.items?.length) {
          if (event.oldIndicies[0].index > event.newIndicies[0].index) {
            startIndex = event.newIndicies[0].index;
          } else {
            startIndex = event.oldIndicies[0].index;
          }

          if (event.oldIndicies[event.items.length-1].index > event.newIndicies[event.items.length-1].index) {
            endIndex = event.oldIndicies[event.items.length-1].index;
          } else {
            endIndex = event.newIndicies[event.items.length-1].index;
          }

        } else {
          oldIndex = event.oldIndex
          newIndex = event.newIndex

          if (oldIndex > newIndex) {
            startIndex = newIndex;
            endIndex = oldIndex;
          } else {
            startIndex = oldIndex;
            endIndex = newIndex;
          }
        }

        changedItems = {}
        changedItems[itemsName] = {}
        for (index = startIndex; index <= endIndex; index++) {
          changedItems[itemsName][index] = itemsIds[index];
        }

        $.ajax({
          url: this.el.dataset.url,
          type: "PATCH",
          data: changedItems
        });
      }
    });
  });

  // Fix for multi sortable and row select
  $(".multisortable").children().each(function() {
    $(this).onClassChange((element, newClass) => {
      if (newClass.includes('active')) {
        Sortable.utils.select(element);
      } else {
        Sortable.utils.deselect(element);
      }
    });
  });

  tinymce.init({
    selector: '.tinymce',
    skin: false,
    content_css: false,
    branding: false,
    language: 'de',
    height: 500,
    plugins: 'link, autoresize, pagebreak',
    pagebreak_separator: '<div class="alwaysbreak"></div>',
  })

  $("input.datepicker").datetimepicker(window.options);
}

$(window).resize(function() {
  resizeLargeTables();
})

resizeLargeTables = function() {
  $('.table-scrollable').each(function() {
    $(this).css('max-height', ($(window).height() - parseInt($(this).data("height"))) + "px");
  })
}

$(document).on("click", '.btn-clear-datepicker', function() {
  var input = $(this).closest(".form-group").find("input.datepicker")
  if (!input) { return }

  input.val("")
});

// jQuery extension method:
$.fn.onClassChange = function(cb) {
  return $(this).each((_, el) => {
    new MutationObserver(mutations => {
      mutations.forEach(mutation => cb && cb(mutation.target, mutation.target.className));
    }).observe(el, {
      attributes: true,
      attributeFilter: ['class'] // only listen for class attribute changes 
    });
  });
}
