$(document).on("turbolinks:load", function () {
  window.consumptionDatepicker()
});

$(document).on("change", "[data-toggle=select-supplier]", function (event) {
  let form = $(event.target).closest('form')[0]
  sendRequest(form);
})

$(document).on("click", ".edit-target-quantity-button, .accept-target-quantity-button, .discard-target-quantity-button", function (event) {
  let actionButton = event.currentTarget
  let targetStockContainer = actionButton.closest('.target-quantity-container')
  let showContainer = targetStockContainer.querySelector('.show-container')
  let editContainer = targetStockContainer.querySelector('.edit-container')

  let branchId = parseInt(targetStockContainer.getAttribute('data-branch-id'))
  let productId = parseInt(targetStockContainer.getAttribute('data-product-id'))
  let url = targetStockContainer.getAttribute('data-url')
  let targetStock = parseInt(targetStockContainer.querySelector('.target-quantity').textContent)
  let targetStockInput = targetStockContainer.querySelector('input')

  if (actionButton.classList.contains('edit-target-quantity-button')) {
    showContainer.classList.add('d-none')
    editContainer.classList.remove('d-none')
    targetStockInput.value = targetStock
  } else if (actionButton.classList.contains('accept-target-quantity-button')) {
    targetStock = targetStockInput.value

    $.ajax({
      type: 'patch',
      url: url,
      data: {
        product_id: productId,
        target_stock: targetStock,
        branch_id: branchId
      }
    }).done(function () {
      targetStockContainer.querySelector('.target-quantity').innerText = targetStock
      targetStockContainer.classList.add('edited')
    }).fail(function () {
      alert("Kein Standard Artikel für diese Filiale gefunden")
    });

    editContainer.classList.add('d-none')
    showContainer.classList.remove('d-none')
  } else if (actionButton.classList.contains('discard-target-quantity-button')) {
    editContainer.classList.add('d-none')
    showContainer.classList.remove('d-none')
  }
})

window.consumptionDatepicker = function () {
  let start_date = $("input#consumption_chart_start_date").val();
  let end_date = $("input#consumption_chart_end_date").val();

  $('input#consumption_chart_start_date, input#consumption_chart_end_date').datetimepicker("destroy");

  var start_date_options = $.extend({}, window.options, { date: new Date(start_date) });
  var end_date_options = $.extend({}, window.options, { date: new Date(end_date) });

  $("input#consumption_chart_start_date").datetimepicker(start_date_options);
  $("input#consumption_chart_end_date").datetimepicker(end_date_options);
}

function sendRequest(form) {
  let formData = new FormData(form)
  formData.append("consumption_chart[skip_validations]", true)
  let data = Object.fromEntries(formData)

  $.ajax({
    type: form.method,
    url: form.action,
    data: data,
    dataType: "script",
  });
}